export default class Parallax{

    constructor(){
        this.interval = 100;
        this.lastTime = new Date().getTime() - this.interval;
        this.init();
    }

    init(){

        window.addEventListener('scroll', e=>{

            // スクロール処理を間引く
            if((this.lastTime + this.interval) <= new Date().getTime()){
                this.lastTime = new Date().getTime();
                this.execParallax(window.pageYOffset);
            }
        });
    }

    execParallax(yy){
        TweenMax.to('.keyvis__bgs__layer.-layer1', 1, {transform:`translate(0, ${yy * 0.02}px)`});
        TweenMax.to('.keyvis__bgs__layer.-layer2', 1, {transform:`translate(0, ${yy * 0.04}px)`});
        TweenMax.to('.keyvis__waterdrop', 1, {transform:`translate(0, ${yy * -0.03}px)`});
        TweenMax.to('.keyvis__image__inner', 1, {transform:`translate(0, ${yy * 0.01}px)`});

        TweenMax.to('.polka', 1, {transform:`translate(0, ${yy * 0.04}px)`});
        TweenMax.to('.waterdrop', 1, {transform:`translate(0, ${yy * -0.03}px)`});
    }
}
