export default class Intro{

    constructor(){
        this.init();
    }

    init(){
        this.setAnime();
    }


    setAnime(){
        this.animeInit();

        this.tl = new TimelineMax();
        this.tl
        .call(this.pageFade.bind(this), null, null, 0.5)
			.call(this.showVisual.bind(this), null, null, 0.5)
            .call(this.showHeader.bind(this), null, null, 1.2)
            .call(this.showCatch.bind(this), null, null, 2)
            .call(this.animeComplete.bind(this), null, null, 2);
    }

    animeInit(){
        TweenMax.set('.keyvis__releaseday span', {opacity:0, transform:'scale(0.8)'});
        TweenMax.set('.keyvis__image__inner', {opacity:0, transform:'scale(1.3)'});
        TweenMax.set('.keyvis__news', {opacity:0, transform:'translate(0, 20px)'});
        TweenMax.set('.keyvis__header__title span', {opacity:0, transform:'scale(1.5) rotate(-10deg)'});
        TweenMax.set('.keyvis__catchcopysp', {opacity:0});
        TweenMax.set('.keyvis__catchcopy__1 span', {height:0});
        TweenMax.set('.keyvis__catchcopy__2 span', {height:0});
        TweenMax.set('.keyvis__ova', {opacity:0, transform:'scale(1.7)'});
    }

    pageFade(){
        TweenMax.set('.c-wrapper', {visibility:'visible', opacity:0});
        TweenMax.to('.c-wrapper', 0.7, {opacity:1, ease:Power2.easeOut});
    }

    showVisual(){
        TweenMax.to('.keyvis__image__inner', 0.5, {opacity:1, transform:'scale(1)', ease:Power2.easeOut});
        TweenMax.to('.keyvis__releaseday span', 0.7, {delay:0.2, opacity:1, transform:'scale(1)', ease:Back.easeOut.config(1)});
    }

    showHeader(){
        TweenMax.to('.keyvis__header__title span', 0.7, {opacity:1, transform:'scale(1) rotate(0)', ease:Back.easeOut.config(1.4)});
        TweenMax.to('.keyvis__news', 0.7, {opacity:1, transform:'translate(0, 0)', ease:Back.easeOut.config(1)});
        TweenMax.to('.keyvis__ova', 0.6, {opacity:1, transform:'scale(1)', ease:Back.easeOut.config(1.4)});
    }

    showCatch(){
        // sp
        TweenMax.to('.keyvis__catchcopysp', 1, {opacity:1, ease:Power2.easeOut});

        // pc
        TweenMax.to('.keyvis__catchcopy__1 span', 0.6, {height:'100%', ease:Power2.easeOut});
        TweenMax.to('.keyvis__catchcopy__2 span', 0.6, {delay: 0.6, height:'100%', ease:Power2.easeOut});
    }

    animeComplete(){
        //
    }
}
